import React, { useEffect, useState } from "react";
import Layout from "@components/layouts/Layout";
import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import useSWR from "swr";
import axios from "axios";
import dayjs from "dayjs";
import Products from "@components/sections/Products";
import ProductsNew from "@components/sections/products2/Products";
// import { Link } from "gatsby";

const endpoints = {
    products: "/.netlify/functions/products",
};

const Component = ({ pageContext: { item } }) => {
    const {
        data: result,
        isLoading,
        isValidating,
    } = useSWR(endpoints.products, axios.get, {
        dedupingInterval: 30 * 1000,
    });

    const [filteredItems, setFilteredItems] = useState([]);
    const [onlineItems, setOnlineItems] = useState([]);
    const [bookItems, setBookItems] = useState([]);
    const [faceToFaceItems, setFaceToFaceItems] = useState([]);

    useEffect(() => {
        // Filtramos y ordenamos los productos
        const items = result?.data?.products
            ?.filter(x => x.enabled && !x.hidden && x.category?.includes(item.name))
            // Convertimos fechas
            .map(p => {
                const { created_at, updated_at, ...rest } = p;
                return {
                    ...rest,
                    created_at: dayjs(created_at).toDate(),
                    updated_at: dayjs(updated_at).toDate(),
                };
            })
            // Ordenamos por stock y luego por fecha
            .sort((a, b) => b.stock - a.stock || b.created_at - a.created_at);

        setFilteredItems(items);
    }, [result, item.name]);

    useEffect(() => {
        setOnlineItems(filteredItems?.filter(x => x.category?.includes("online")) || []);

        setBookItems(filteredItems?.filter(x => x.category?.includes("books")) || []);

        setFaceToFaceItems(filteredItems?.filter(x => x.category?.includes("face-to-face")) || []);
    }, [filteredItems]);

    return (
        <>
            <Layout
                seo={{
                    title: item.title,
                    description: item.description,
                    image: item.image,
                }}
            >
                {/** Image Head */}
                <div className={styles.element.container} style={{ height: "calc(100vh - 149px)" }}>
                    {/** Imagen Background */}
                    <div className={styles.element.imageContainer}>
                        <Image
                            filename={item.background}
                            alt={item.title}
                            className={`${styles.element.image}`}
                        />
                    </div>

                    {/** Imagen Foreground */}
                    <div className={styles.element.imageForegroundContainer}>
                        <Image
                            alt={item.title}
                            filename={item.image}
                            className={styles.element.imageForeground}
                        />
                    </div>

                    {/** Background Gradient */}
                    <div
                        className={`${styles.element.backgroundOverlay} bg-opacity-50 opacity-75`}
                    />

                    {/** Título */}
                    <div className="z-40 h-full relative flex flex-col">
                        <div className="mx-auto my-auto text-white max-w-4xl">
                            <h1 className="font-montserrat uppercase text-2xl md:text-4xl lg:text-6xl font-bold text-center text-shadow">
                                {item.title}
                            </h1>
                        </div>
                    </div>
                </div>

                {/** Contenido */}
                <div className="container lg:px-0 pt-10 pb-20 gap-10 grid grid-cols-1">
                    {/** Cursos presenciales */}
                    <section className="bg-gray-50">
                        <Title>Formación presencial</Title>
                        <Text>
                            En nuestra academia de Hospitalet, al lado de la Ciudad de la Justicia,
                            impartimos clases con profesores que son compañeros policías con toda la
                            experiencia para colocarte entre los mejores.
                        </Text>

                        <div className="mt-10 space-y-5">
                            {/*{filteredItems && (
                                <Products items={faceToFaceItems} />
                            )}*/}

                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={faceToFaceItems}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={faceToFaceItems} />
                            )}
                        </div>
                    </section>

                    {/** Cursos online */}
                    <section className="bg-gray-50">
                        <Title>Formación online</Title>
                        <Text>
                            Prepárate desde casa o cualquier lugar sin horarios. Temario,
                            psicotécnicos, inglés, aptitudinales, casos prácticos, podcast… todo lo
                            que necesitas.
                        </Text>

                        <div className="mt-10 space-y-5">
                            {/*{filteredItems && <Products items={onlineItems} />}*/}

                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={onlineItems}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={onlineItems} />
                            )}
                        </div>
                    </section>

                    {/** Libros */}
                    <section className="bg-gray-50">
                        <Title>Formación con libros</Title>

                        <Text>
                            Todo el temario imprescindible de forma sencilla y amena. Estructurado
                            para estudiar de forma rápida y que se te quede la información. Escrito
                            por policías que fueron opositores.
                        </Text>
                        <div className="mt-10 space-y-5">
                            {/*{filteredItems && <Products items={bookItems} />}*/}

                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={bookItems}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={bookItems} />
                            )}
                        </div>
                    </section>
                </div>
            </Layout>
        </>
    );
};

const styles = {
    element: {
        container: "bg-gray-200 relative",
        backgroundOverlay:
            "absolute z-30 w-full h-full top-0 left-0 bg-black rounded-xl bg-gradient-to-b from-transparent to-gray-black duration-300",
        imageContainer: "absolute z-0 w-full h-full overflow-hidden",
        image: "w-full h-full object-cover",
        textContainer: "absolute w-full bottom-0 left-0 p-5 md:p-10 z-50",
        title: "font-montserrat font-medium text-2xl lg:text-3xl text-white",
        description: "font-montserrat text-white lg:text-lg",
        button: "bg-black bg-opacity-50 rounded-xl py-2 px-5 font-medium text-white font-poppins inline-block lg:text-base text-sm",
        imageForegroundContainer: "absolute bottom-0 right-0 h-full w-full lg:w-2/3",
        imageForeground: "w-full h-full object-cover rounded-br-xl",
    },
};

export default Component;
